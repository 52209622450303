import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container } from "../components/Container";

const NotFoundPage = () => (
  <Layout heroSection={false}>
    <SEO title="404: Not found" />
    <Container>
      <div
        style={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h1>404. Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
